import React, { useEffect, useState } from 'react'
import { Box, Typography, useTheme, TextField, IconButton, Modal, Select, MenuItem } from "@mui/material";
import { tokens } from "../../../theme";
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { getJson, postJson } from "../../../data/dataHook.js"
import Header from "../../../components/Header";
const PdfList = () => {
  const [pdfFiles, setPdfFiles] = useState([])
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  useEffect(() => {
    getJson("/getListOfPDF").then(data => setPdfFiles(data))
  }, [])  // Empty dependency array ensures this runs only on mount

  return (
    <Box 
      m="40px"
      sx={{ 
        '& .css-8er80j-MuiPaper-root-MuiTableContainer-root': { backgroundColor: colors.primary[800] },
        '& .MuiTableBody-root .MuiTableCell-root': { cursor: 'pointer' }
      }}
    >
    <Header title="PDF" subtitle='Fil navne er "medlemsnummer - id på andsøgning - dato".' />
    <List>
      {pdfFiles.map((file, index) => (
        <ListItem key={index} button component="a" href={`https://api.rdhj.dk/application-pdf/${file}`} target="_blank">
          <ListItemText primary={file} />
        </ListItem>
      ))}
    </List>
    </Box>
  )
}

export default PdfList